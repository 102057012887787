import { merge, Observable, Subject, Subscription } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
} from "rxjs/operators";
import { searchParams$, setSearchParams } from "../searchParams";
import { mutateSearchParams } from "../utils";

export interface DebouncedUrlParam<T> {
  readonly debounced$: Observable<T>;
  readonly set: (value: T) => void;
  readonly subscribe: () => Subscription;
  readonly sync$: Observable<T>;
}

export function createDebouncedUrlParam<T>(
  searchParamKey: string,
  paramToValue: (param: string | undefined) => T,
  valueToParam: (value: T) => string | undefined
): DebouncedUrlParam<T> {
  const local$ = new Subject<T>();

  const set = (value: T) => {
    local$.next(value);
  };

  const url$ = searchParams$.pipe(
    map((params) => params.get(searchParamKey) ?? undefined),
    map((param) => paramToValue(param)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  const sync$ = merge(local$, url$).pipe(
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  const subscribe = () =>
    local$
      .pipe(
        debounceTime(500),
        map((value) => valueToParam(value)),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        const newSearchParams = mutateSearchParams((searchParams) => {
          if (typeof value === "undefined") {
            searchParams.delete(searchParamKey);
          } else {
            searchParams.set(searchParamKey, value);
          }
        });

        setSearchParams(newSearchParams);
      });

  return { debounced$: url$, set, subscribe, sync$ };
}
