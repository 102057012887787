import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { memo, useLayoutEffect, useMemo } from "react";
import { BehaviorSubject, fromEvent, of } from "rxjs";
import {
  combineLatestWith,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
} from "rxjs/operators";
import { noop } from "../../lib/utils";
import type { Column } from "./Column";
import type { GenericRow } from "./GenericRow";
import classes from "./Table.module.css";
import { Placeholder } from "./utils";

const NEAR_BOTTOM_THRESHOLD = 1000;

const HEAD_HEIGHT = 65;
const ROW_HEIGHT = 46;

interface TableRowProps<T extends GenericRow> {
  readonly columns: readonly Column<T>[];
  readonly row: T | undefined;
}

function TableRowBase<T extends GenericRow>(props: TableRowProps<T>) {
  const { columns, row } = props;

  return (
    <>
      {columns.map((column) => {
        const { CellFormatter } = column;

        return (
          <MuiTableCell
            className={classes.cell}
            component="div"
            key={column.id}
          >
            {typeof row === "undefined" ? (
              <Placeholder />
            ) : (
              <CellFormatter row={row} />
            )}
          </MuiTableCell>
        );
      })}
    </>
  );
}

const TableRow = memo(TableRowBase) as typeof TableRowBase;

class TableState {
  readonly #tableElement$ = new BehaviorSubject<HTMLDivElement | null>(null);
  readonly #refresh$ = new BehaviorSubject<undefined>(undefined);

  readonly isNearBottom$ = this.#tableElement$.pipe(
    switchMap((table) => {
      if (table === null) {
        return of(false);
      } else {
        return fromEvent(table, "scroll").pipe(
          startWith(null),
          combineLatestWith(this.#refresh$),
          map(() => {
            const { clientHeight, scrollHeight, scrollTop } = table;
            const isNearBottom =
              scrollTop + clientHeight > scrollHeight - NEAR_BOTTOM_THRESHOLD;

            return isNearBottom;
          })
        );
      }
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly handleRender = () => {
    this.#refresh$.next(undefined);
  };
  readonly setTableElement = (table: HTMLDivElement | null) => {
    this.#tableElement$.next(table);
  };
}

interface TableProps<T extends GenericRow> {
  readonly columns: readonly Column<T>[];
  readonly rows: readonly (T | undefined)[];
  onIsNearBottomChange?(isNearBottom: boolean): void;
}

function TableBase<T extends GenericRow>(props: TableProps<T>) {
  const { columns, onIsNearBottomChange, rows } = props;
  const state = useMemo(() => new TableState(), []);

  useLayoutEffect(() => {
    state.handleRender();
  });

  useLayoutEffect(() => {
    if (typeof onIsNearBottomChange === "undefined") {
      return noop;
    } else {
      const subscription = state.isNearBottom$.subscribe((isNearBottom) => {
        onIsNearBottomChange(isNearBottom);
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [onIsNearBottomChange, state]);

  return (
    <MuiTable
      className={classes.root}
      component="div"
      ref={state.setTableElement}
      size="small"
      stickyHeader
    >
      <MuiTableHead
        className={classes.head}
        component="div"
        style={{
          gridAutoRows: `${HEAD_HEIGHT}px`,
          gridTemplateColumns: columns
            .map((column) =>
              typeof column.width === "string"
                ? column.width
                : `${column.width}px`
            )
            .join(" "),
        }}
      >
        {columns.map((column) => {
          const { HeaderFormatter } = column;

          return (
            <MuiTableCell
              className={classes.cell}
              component="div"
              key={column.id}
            >
              <HeaderFormatter />
            </MuiTableCell>
          );
        })}
      </MuiTableHead>
      <MuiTableBody
        className={classes.body}
        component="div"
        style={{
          gridAutoRows: `${ROW_HEIGHT}px`,
          gridTemplateColumns: columns
            .map((column) =>
              typeof column.width === "string"
                ? column.width
                : `${column.width}px`
            )
            .join(" "),
        }}
      >
        {rows.map((row, index) => {
          const rowKey =
            typeof row === "undefined"
              ? `placeholder_${index}`
              : `id_${row.id}`;

          return <TableRow columns={columns} key={rowKey} row={row} />;
        })}
      </MuiTableBody>
    </MuiTable>
  );
}

export const Table = memo(TableBase) as typeof TableBase;
