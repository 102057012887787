import Gavel from "@mui/icons-material/Gavel";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Account, BanStatus, ChangeBanStatus, useApi } from "../../../../api";
import { AccountPicker } from "../../../../components/AccountPicker";
import { Picker, SingleSelect } from "../../../../components/Picker";
import {
  BanStatusOptions,
  HwidOptions,
} from "../../../../components/PickerOptions";
import { isError } from "../../../../lib/utils";
import type { AccountRow } from "../AccountRow";
import classes from "./AccountActionsBan.module.css";

interface AccountActionsBanProps {
  account: AccountRow;
  onBanStatusChange: (changeBanStatus: ChangeBanStatus) => Promise<void>;
}

export const AccountActionsBan = (props: AccountActionsBanProps) => {
  const { account, onBanStatusChange } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [banReason, setBanReason] = useState("");
  const [banStatus, setBanStatus] = useState<BanStatus>(BanStatus.NotBanned);
  const [dialogTitleId] = useState(() => uuid());
  const [error, setError] = useState<Error | undefined>(undefined);
  const [hwid, setHwid] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [relatedAccount, setRelatedAccount] = useState<Account | undefined>(
    undefined
  );

  const { api } = useApi();

  const handleCancel = useCallback(() => {
    if (!loading) {
      setOpen(false);
    }
  }, [loading]);

  const handleClickOpen = useCallback(() => {
    setBanReason("");
    setBanStatus(
      account.banned
        ? account.spreadBanned
          ? BanStatus.HwidBanned
          : BanStatus.Banned
        : BanStatus.NotBanned
    );
    setError(undefined);
    setHwid(undefined);
    setLoading(false);
    setRelatedAccount(undefined);

    setOpen(true);
  }, [account.banned, account.spreadBanned]);

  const handleBanStatusChange = (value: BanStatus | undefined) => {
    setBanStatus(value ?? BanStatus.NotBanned);
  };

  const handleBanReasonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBanReason(event.currentTarget.value);
    },
    []
  );

  const handleApply = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      setError(undefined);

      if (banStatus === BanStatus.HwidBanned && typeof hwid === "undefined") {
        setError(new Error("Please select a HWID to be banned!"));
      } else {
        setLoading(true);
        onBanStatusChange({
          accountId: account.id,
          banStatus,
          changeReason: banReason,
          hwid,
          relatedAccountId: relatedAccount?.id,
        })
          .then(() => {
            setOpen(false);
          })
          .finally(() => {
            setLoading(false);
          })
          .catch((error: unknown) => {
            if (isError(error)) {
              setError(error);
            }

            console.error(error);
          });
      }
    },
    [
      account.id,
      banReason,
      banStatus,
      hwid,
      onBanStatusChange,
      relatedAccount?.id,
    ]
  );

  return (
    <>
      <span className={classes.root}>
        <Tooltip title={String(t("AccountActionsBan.button"))}>
          <IconButton onClick={handleClickOpen} size="small">
            <Gavel />
          </IconButton>
        </Tooltip>
      </span>
      <Dialog
        aria-labelledby={dialogTitleId}
        fullWidth={true}
        maxWidth="xs"
        onClose={handleCancel}
        open={open}
      >
        <form onSubmit={handleApply}>
          <DialogTitle id={dialogTitleId}>
            {t("AccountActionsBan.title")}
          </DialogTitle>
          {typeof error !== "undefined" && (
            <DialogContent>
              <Typography color="error" variant="body2">
                {error.toString()}
              </Typography>
            </DialogContent>
          )}
          <DialogContent>
            <Picker
              autoFocus
              disabled={loading}
              fullWidth
              label={t("AccountActionsBan.banStatus")}
              margin="dense"
            >
              <SingleSelect
                onChange={handleBanStatusChange}
                value={banStatus}
              />
              <BanStatusOptions />
            </Picker>
            <Picker
              allowEmpty
              disabled={loading || banStatus !== BanStatus.HwidBanned}
              fullWidth
              label={t("AccountActionsBan.hwid")}
              margin="dense"
            >
              <SingleSelect onChange={setHwid} value={hwid} />
              <HwidOptions accountId={account.id} api={api} />
            </Picker>
            <TextField
              disabled={loading}
              fullWidth
              label={t("AccountActionsBan.banReason")}
              margin="dense"
              onChange={handleBanReasonChange}
              value={banReason}
            />
            <AccountPicker
              account={relatedAccount}
              api={api}
              disabled={loading}
              fullWidth
              label={t("AccountActionsBan.relatedAccount")}
              margin="dense"
              onChange={setRelatedAccount}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={handleCancel} color="primary">
              {t("AccountActionsBan.cancel")}
            </Button>
            <LoadingButton
              color="primary"
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              type="submit"
              variant="contained"
            >
              {t("AccountActionsBan.apply")}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
