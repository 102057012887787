import type { UseTranslationResponse } from "react-i18next";
import { BanStatus } from "../../api";
import { assertNever } from "../utils";

export function localizeBanStatus(
  t: UseTranslationResponse<string, undefined>[0],
  banStatus: BanStatus
): string {
  switch (banStatus) {
    case BanStatus.Banned:
      return t("BanStatuses.banned");
    case BanStatus.HwidBanned:
      return t("BanStatuses.hwidBanned");
    case BanStatus.NotBanned:
      return t("BanStatuses.notBanned");
    default:
      throw assertNever(banStatus);
  }
}
