import TextField from "@mui/material/TextField";
import { useCallback } from "react";
import type { Observable } from "rxjs";
import { useObservable } from "../../hooks/useObservable";
import { selectOnFocusHandler } from "./utils";

interface StringFilterProps {
  readonly label: string;
  readonly onChange: (value: string | undefined) => void;
  readonly value$: Observable<string | undefined>;
}

export const StringFilter = (props: StringFilterProps) => {
  const { label, onChange, value$ } = props;

  const value = useObservable(value$, undefined);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;

      onChange(value === "" ? undefined : value);
    },
    [onChange]
  );

  return (
    <TextField
      fullWidth
      label={label}
      onFocus={selectOnFocusHandler}
      size="small"
      value={value ?? ""}
      onChange={handleChange}
    />
  );
};
