import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BehaviorSubject, combineLatest, defer, of } from "rxjs";
import { catchError, combineLatestWith, map } from "rxjs/operators";
import { Account, ChangeBanStatus, useApi } from "../../../api";
import {
  AccountRankFormatter,
  BfpFormatter,
  BooleanFormatter,
  ForumIdFormatter,
  GoldFormatter,
  IdFormatter,
  InfiniTable,
  NumberFormatter,
  StringFormatter,
} from "../../../components/Table";
import type { InfiniColumn } from "../../../components/Table";
import {
  NoFilter,
  NumberFilter,
  StringFilter,
} from "../../../components/TableFilters";
import { useObservable } from "../../../hooks/useObservable";
import type { InfiniScrollFetcher } from "../../../lib/InfiniScroll";
import { AccountActions } from "./AccountActions";
import type { AccountRow } from "./AccountRow";
import type { AccountsTableFilters } from "./AccountsTableFilters";

const BATCH_SIZE = 64;

function apiAccountToRow(account: Account): AccountRow {
  return {
    banned: account.banned,
    banReason: account.banReason,
    bfp: account.bfp,
    experience: account.experience,
    forumId: account.forumId,
    gold: account.gold,
    id: account.id,
    name: account.name,
    pvpElo: account.pvpElo,
    rank: account.rank,
    spreadBanned: account.spreadBanned,
  };
}

interface AccountsTableProps {
  filters: AccountsTableFilters;
}

export const AccountsTable = (props: AccountsTableProps) => {
  const { filters } = props;
  const { t } = useTranslation();
  const { api } = useApi();

  const refresh$ = useMemo(() => new BehaviorSubject(undefined), []);

  const handleAccountChange = useCallback(
    async (account: AccountRow) => {
      const { id, name } = account;

      try {
        await api.updateAccount({
          id,
          name,
        });
      } catch (error: unknown) {
        if (error instanceof Response && error.status === 422) {
          throw new Error("Illegal input!");
        } else {
          throw new Error("Unexpected error!");
        }
      }

      refresh$.next(undefined);
    },
    [api, refresh$]
  );

  const handleBanStatusChange = useCallback(
    async (changeBanStatus: ChangeBanStatus) => {
      try {
        await api.changeBanStatus(changeBanStatus);
      } catch (error: unknown) {
        if (error instanceof Response && error.status === 422) {
          throw new Error("Illegal input!");
        } else {
          throw new Error("Unexpected error!");
        }
      }

      refresh$.next(undefined);
    },
    [api, refresh$]
  );

  const columns = useMemo(
    (): InfiniColumn<AccountRow>[] => [
      {
        CellFormatter: ({ row }) => (
          <AccountActions
            account={row}
            onBanStatusChange={handleBanStatusChange}
            onChange={handleAccountChange}
          />
        ),
        HeaderFormatter: () => <NoFilter label={t("Accounts.actions")} />,
        id: "actions",
        width: 168,
      },
      {
        CellFormatter: ({ row }) => <IdFormatter value={row.id} />,
        HeaderFormatter: () => (
          <NumberFilter
            label={t("Accounts.id")}
            onChange={filters.setId}
            value$={filters.idSync$}
          />
        ),
        id: "id",
        width: 120,
      },
      {
        id: "forumId",
        CellFormatter: ({ row }) => <ForumIdFormatter value={row.forumId} />,
        HeaderFormatter: () => (
          <NumberFilter
            label={t("Accounts.forumId")}
            onChange={filters.setForumId}
            value$={filters.forumIdSync$}
          />
        ),
        width: 130,
      },
      {
        id: "name",
        CellFormatter: ({ row }) => <StringFormatter value={row.name} />,
        HeaderFormatter: () => (
          <StringFilter
            label={t("Accounts.name")}
            onChange={filters.setName}
            value$={filters.nameSync$}
          />
        ),
        width: 150,
      },
      {
        id: "experience",
        CellFormatter: ({ row }) => <NumberFormatter value={row.experience} />,
        HeaderFormatter: () => <NoFilter label={t("Accounts.experience")} />,
        width: 120,
      },
      {
        id: "pvpElo",
        CellFormatter: ({ row }) => <NumberFormatter value={row.pvpElo} />,
        HeaderFormatter: () => <NoFilter label={t("Accounts.pvpElo")} />,
        width: 100,
      },
      {
        id: "gold",
        CellFormatter: ({ row }) => <GoldFormatter value={row.gold} />,
        HeaderFormatter: () => <NoFilter label={t("Accounts.gold")} />,
        width: 180,
      },
      {
        id: "bfp",
        CellFormatter: ({ row }) => <BfpFormatter value={row.bfp} />,
        HeaderFormatter: () => <NoFilter label={t("Accounts.bfp")} />,
        width: 180,
      },
      {
        id: "rank",
        CellFormatter: ({ row }) => <AccountRankFormatter value={row.rank} />,
        HeaderFormatter: () => <NoFilter label={t("Accounts.rank")} />,
        width: 100,
      },
      {
        id: "banned",
        CellFormatter: ({ row }) => <BooleanFormatter value={row.banned} />,
        HeaderFormatter: () => <NoFilter label={t("Accounts.banned")} />,
        width: 120,
      },
      {
        id: "spreadBanned",
        CellFormatter: ({ row }) => (
          <BooleanFormatter value={row.spreadBanned} />
        ),
        HeaderFormatter: () => <NoFilter label={t("Accounts.spreadBanned")} />,
        width: 120,
      },
      {
        id: "banReason",
        CellFormatter: ({ row }) => <StringFormatter value={row.banReason} />,
        HeaderFormatter: () => <NoFilter label={t("Accounts.banReason")} />,
        width: "minmax(400px, 1fr)",
      },
    ],
    [filters, handleAccountChange, handleBanStatusChange, t]
  );

  const fetcher$ = useMemo(() => {
    const filters$ = combineLatest([
      filters.forumId$,
      filters.id$,
      filters.name$,
    ]);

    return filters$.pipe(
      combineLatestWith(refresh$),
      map(
        ([[forumId, id, name]]): InfiniScrollFetcher<AccountRow, number> =>
          (offset = 0) =>
            defer(async () => {
              const accounts = await api.getAccounts({
                filters: {
                  forumId,
                  id,
                  nameStartsWith: name,
                },
                limit: BATCH_SIZE,
                offset,
              });

              const data = accounts.map((account) => apiAccountToRow(account));

              return {
                data,
                next: offset + data.length,
              };
            }).pipe(
              catchError((error) => {
                console.error(error);

                return of({
                  data: [],
                  next: undefined,
                });
              })
            )
      )
    );
  }, [api, filters, refresh$]);

  const fetcher = useObservable(fetcher$, () =>
    of({ data: [], next: undefined })
  );

  return <InfiniTable columns={columns} fetcher={fetcher} />;
};
