import type { ChangeBanStatus } from "../../../api";
import classes from "./AccountActions.module.css";
import {
  AccountActionsBan,
  AccountActionsDecks,
  AccountActionsEdit,
  AccountActionsMultiAccount,
} from "./AccountActionsOptions";
import type { AccountRow } from "./AccountRow";

interface AccountActionsProps {
  account: AccountRow;
  onBanStatusChange: (changeBanStatus: ChangeBanStatus) => Promise<void>;
  onChange: (account: AccountRow) => Promise<void>;
}

export const AccountActions = (props: AccountActionsProps) => {
  const { account, onBanStatusChange, onChange } = props;

  return (
    <span className={classes.root}>
      <AccountActionsEdit account={account} onChange={onChange} />
      <AccountActionsBan
        account={account}
        onBanStatusChange={onBanStatusChange}
      />
      <AccountActionsMultiAccount account={account} />
      <AccountActionsDecks account={account} />
    </span>
  );
};
