import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { Api, ConnectionLog } from "../../api";
import type { Option } from "../Picker";
import { Options } from "../Picker";

const FETCH_LIMIT = 128;

interface Hwid {
  hwid: string;
  lastUsed: Date;
}

function connectionLogsToHwids(connectionLogs: ConnectionLog[]): Hwid[] {
  const hwidMap = new Map<string, Date>();

  for (const connectionLog of connectionLogs) {
    const { hwid, lastUsed } = connectionLog;
    const oldLastUsed = hwidMap.get(hwid);

    if (typeof oldLastUsed === "undefined" || oldLastUsed < lastUsed) {
      hwidMap.set(hwid, lastUsed);
    }
  }

  const hwids: Hwid[] = [];

  for (const [hwid, lastUsed] of hwidMap.entries()) {
    hwids.push({ hwid, lastUsed });
  }

  hwids.sort((lhs, rhs) => rhs.lastUsed.valueOf() - lhs.lastUsed.valueOf());

  return hwids;
}

interface HwidOptionsProps {
  accountId: number | undefined;
  api: Api;
}

export const HwidOptions = (props: HwidOptionsProps) => {
  const { accountId, api } = props;

  const { t } = useTranslation();

  const [hwids, setHwids] = useState<Hwid[] | undefined>(undefined);
  const options: Option<string>[] | undefined = useMemo(
    () =>
      hwids?.map((hwid) => ({
        localized: `${hwid.hwid} (${t("General.fullDate", {
          value: hwid.lastUsed,
        })})`,
        value: hwid.hwid,
      })),
    [hwids, t]
  );

  const fetchHwids = useCallback(async () => {
    if (typeof accountId === "undefined") {
      return undefined;
    }

    const connectionLogs = await api.getConnectionLogs({
      filters: {
        accountId,
      },
      limit: FETCH_LIMIT,
      sort: [["lastUsed", "desc"]],
    });

    return connectionLogsToHwids(connectionLogs);
  }, [api, accountId]);

  useEffect(() => {
    fetchHwids().then((hwids) => {
      setHwids(hwids);
    });
  }, [fetchHwids]);

  if (options !== undefined) {
    return <Options options={options} />;
  } else {
    return null;
  }
};
