import { useCallback, useContext, useLayoutEffect } from "react";
import { useObservableOnChange } from "../../hooks/useObservableOnChange";
import type { PickerState } from "./PickerState";
import { pickerStateContext } from "./pickerStateContext";

interface SingleSelectProps<T> {
  onChange: (value: T | undefined) => void;
  value: T | undefined;
}

export function SingleSelect<T>(props: SingleSelectProps<T>) {
  const { onChange, value } = props;

  const state = useContext(pickerStateContext) as PickerState<T> | undefined;

  if (state === undefined) {
    throw new Error(
      "Unable to locate PickerState! Make sure to nest inside a Picker!"
    );
  }

  useLayoutEffect(() => {
    state.setIsMultiSelect(false);
  }, [state]);

  useLayoutEffect(() => {
    state.setSelectedValues(value === undefined ? new Set() : new Set([value]));
  }, [state, value]);

  const handleChange = useCallback(
    (selectedOptions: Set<T>) => {
      if (selectedOptions.size === 0) {
        onChange(undefined);
      } else {
        onChange([...selectedOptions][0]!);
      }
    },
    [onChange]
  );

  useObservableOnChange(state.selectedValues$, handleChange);

  return null;
}
